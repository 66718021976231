import React from "react";
import imgconselho from "../../assets/imgs/conselho/ban_conselho.png";
import imgcontato from "../../assets/imgs/contato/contato.jpg";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

const Terceirizacao = () => {
  return (
    <div>
      <div className="hidden md:block"></div>
      <div className="mx-auto pb-20">
        <Header />
        {/* Purple Section */}
        <div className="bg-customPurple py-10 bg-pattern flex justify-center relative">
          <div className="md:w-full md:max-w-[90vw] p-8 grid sm:grid-cols-5 gap-4 items-center">
            {/* Left Side Text */}
            <div className="flex flex-col col-span-3 sm:text-left">
              <h1 className="text-4xl text-white font-bold">
                Entre em contato
              </h1>
              <p className="text-xl text-white pt-4 leading-relaxed">
                Quer falar com a gente? Vamos adorar ouvir
                <br className="hidden sm:block" />
                você, veja como nos encontrar
              </p>
            </div>

            {/* Right Side Image */}
            <div className="relative col-span-2 sm:flex justify-end hidden">
              <img
                src={imgcontato}
                alt="Contatos"
                className="w-full h-auto rounded-sm object-cover"
              />
            </div>
          </div>
        </div>

        <div className="py-10 text-center flex flex-col">
          <div className="self-center max-w-[60vw] bg-white sm:-mt-20 z-10 sm:p-10 sm:px-32">
            {" "}
            <h1 className="text-4xl text-customPurple font-bold">
              FALE COM A GENTE
            </h1>
            {/* Centered Iframe with Scroll Disabled */}
            <iframe
              src="https://blog.adxcompany.com.br/?page_id=262"
              frameBorder="0"
              scrolling="no"
              className="mx-auto block w-full max-w-4xl h-80"
              title="Form"
            ></iframe>
          </div>
        </div>

        {/* Green Section */}
        <div className="flex flex-col text-customPurple text-2xl font-bold items-center pb-10">
          <h1 className="text-center mb-8 font-bold text-2xl">
            Onde nos encontrar
          </h1>
          <div className="grid sm:grid-cols-5 gap-4 w-full max-w-[90vw] rounded-lg border border-gray-300">
            <div className="bg-black col-span-3 flex justify-center items-center text-white text-4xl rounded-l-lg overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3950.381571852783!2d-34.8753042!3d-8.062505!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab18ceeb7ceccd%3A0x2961ec207a3dba2c!2sADX%20Company!5e0!3m2!1spt-BR!2sbr!4v1728325007986!5m2!1spt-BR!2sbr"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="w-full max-w-lg md:max-w-3xl h-96 rounded-l-lg"
                title="Google Maps - ADX Company"
              />
            </div>

            <div className="col-span-2 flex justify-start text-white flex-col text-left p-8">
              <h1 className="text-lg text-black">ADX Company</h1>
              <p className="text-black text-xs font-normal">
              Condomínio Empresarial São Paulo, Avenida Rio Branco<br/><br/>nº243, Sala 301, Recife, Recife-PE, CEP 50030-310
              </p>

              <h1 className="text-lg text-black pt-10">Telefone/Whatsapp</h1>
              <p className="text-black text-xs font-normal">+55 (81) 8937-0175</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terceirizacao;